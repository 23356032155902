import React from "react"

// Components
import Layout from "../components/layout"
import styled from 'styled-components'
import ReactCountryFlag from "react-country-flag"
import { Link } from "gatsby"
import ShoppingCartIcon from '@material-ui/icons/ShoppingCart'
import ForwardIcon from '@material-ui/icons/Forward'

const ImageDisplay = styled.div`
  width : 20vw;
  height : 20vw;
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  background-image: url("${ props => props.src ? props.src : '' }");
  box-shadow: 0 0 5px grey;
  margin-bottom : 5vh;
  min-height: 200px;
  min-width: 200px;
`

const ProductWrapper = styled.div`
  width: 60vw;
  min-height: 200px;
  display: flex;
  align-items: center;
  justify-content : space-between;

  @media (max-width: 767px) {
    flex-direction: column;
    justify-content : flex-start;
  }

`

const InformationWrapper = styled.div`
  width: 30vw;
  min-height: 200px;
  display: flex;
  align-items: flex-start;
  flex-direction : column;

  @media (max-width: 767px) {
    width : 20vw;
    min-width: 200px;
  }
`

const BackLink = styled.div`
  cursor : pointer;
  transition: 0.2s linear;

  :hover& {
    opacity: 0.6
  }
`

const ProductDetails = ({ pageContext }) => {

    const { product } = pageContext
    const { title, description, images, variants } = product
    const image = images.edges.length ? images.edges[0].node.originalSrc : false
    const sku = variants.edges[0].node.sku

    const seo = {
      "@context": "https://schema.org/",
      "@type": "Product",
      "name": title,
      "image": [
        image,
      ],
      "description": description,
      "sku": sku,
      "brand": {
        "@type": "Brand",
        "name": null
      }
    }

  
  return(
    <Layout 
      title = { title }
      structuredData = {seo}
    >
      <div 
        style = {{ 
          width : '60vw', 
          minHeight : '80vh', 
          margin : '0vh 20vw 0 20vw', 
          display: 'flex', 
          flexDirection: 'column', 
          alignItems : 'center' 
        }}
      >
        <div 
          style = {{
            height: '10vh',
            width: '100%',
            display: 'flex',
            alignItems: 'center'
          }}
        >
          <BackLink 
            onClick = { () => window.history.back() }
          >
            <ForwardIcon style = {{ transform : 'rotate(180deg)' , color : '#009688' }} />&nbsp;Back to last page
          </BackLink>
        </div>
        <ProductWrapper>
          <ImageDisplay src = { image } />
          <InformationWrapper>
            <div>
              <h4>{ title }</h4>
            </div>
            <div>
              <h4>{ description }</h4>
            </div>
            <div>
              <p>
                Item number: { sku }<br/>
                Moq: { product.variants.edges[0].node.product.options[1].values }<br/>
                Master Quantity: { product.variants.edges[0].node.product.options[2].values }
              </p>
            </div>
            <ul
                style = {{
                  textDecoration: 'none',
                  listStyleType: 'none',
                  display: 'flex',
                  flexDirection : 'row',
                  alignItems : 'center',
                  margin: 0
                }}
            >
              <li><ShoppingCartIcon />&nbsp;&nbsp;Buy it now </li>
              <li className="nav-item">
                <Link className="nav-link" to="https://canada.siunsons.com/account/login"><ReactCountryFlag countryCode="CA" svg/></Link>
                </li>
              <li className="nav-item">
                <Link className="nav-link" to="https://international.siunsons.com/account/login"><ReactCountryFlag countryCode="US" svg/></Link>
              </li>
            </ul>
          </InformationWrapper>
          </ProductWrapper>
      </div>
    </Layout >
  )
}

export default ProductDetails

